* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.test{
  color: rgb(224, 60, 60);
}

.css-rd586k-MuiContainer-root {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.css-rd586k-MuiContainer-root {
  margin-top: 0px !important;
}

.icon-texts {
  background-color: #F7FBFF;
  border-radius: 8px;
   width: 100%;
   padding: 10px 5px;
}
/* 
.imgdiv{
  border-radius: 12px !important;
} */
.dark-mode .ratingheading p{
  color:white;
}
.dark-mode .numbers-grid{
  color:white;
}
.dark-mode .back-icon{
  color:white;
}
/* .dark-mode .last-grid-page{
  background-color: rgb(41 44 46 / 48%) !important;
} */
.dark-mode .seall-btn button{
  background-color:#212426 !important;
}
.title1 {
  font-size: 26px;
  color: #242424;
  font-weight: 600px;
}

.title2 {
  color: #56687B;
  font-size: 16px;
}

.title3 {
  color: #95A5B5;
  font-size: 16px;
  margin-bottom:18px;
}
.button1{
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #FFFFFF;
  padding: 7px 48px;
  text-decoration: none;
  background: transparent linear-gradient(90deg, #E54949 0%, #E24D4D 4%, #E54949 30%, #CB4444 61%, #9B1F1F 100%) 0% 0% no-repeat padding-box;
}

.parrent-div {
  display: flex;

}
.website-img:hover{
  width:60px;
}
.website:hover{
 color: rgb(29 155 240) !important;
   cursor: pointer !important; 
  transition: 0.5s;
  
}
.website{
  color:black !important;
}
.web-twitter{
  width: 70px;
}
.parentdiv2 {
  display: flex;
  padding-bottom: 25px;
}

.icon-text2 {
  margin-left: 40px;
}

.dynamic-ratings{
  margin-top: 10px;
}


.new {
 font-weight: bold;
 font-size: 20px;
 color: #242424;
 white-space: nowrap;
}

.back-icon{
  font-size: 14px;
  color: #1A1E21;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    /* padding-bottom: 17px; */
}

.back-icon:hover{

  color: rgb(212, 44, 44);

}
/* 
.back-icon:hover{
  background: transparent linear-gradient(90deg, #E54949 0%, #E24D4D 4%, #E54949 30%, #CB4444 61%, #9B1F1F 100%) 0% 0% no-repeat padding-box;
  transition: 0.5s;
  color: white;
  border-radius: 5px;
} */


.gridcomp{
  margin-top: 50px;
  background-color: white !important;
  margin: 0px !important;
  padding-top: 20px !important;

}

.textpara {
  margin-left: 15px;
}

.airnfts {
  text-align: center;

}

.airnfts h6 {
  color: #326DBE;
  font-size: 16px;
}

.airnftstext {
  border-radius: 8px;
  width: 50%;
  background-color: #F7FBFF;
}

.icon-text1 {
  text-align: center;
  margin-left: 25px;
}

.gridcomp {
  margin: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius:10px;
}

.ratingheading {
  font-size: 74px;
  box-sizing: border-box;
  margin-left:20px;
}
.ratingheading2 p{
  font-size:15px;
  margin-bottom: 15px;
}


.ratingheading h1 {
  font-size: 4rem;
  font-weight: 700;
  color: #35383A
}



.inside-div {
  margin-top: 2rem;

}

.info-heading h3 {
  margin-top: 2rem;
  font-weight: bold;
}

.grid-flex {
  margin-top: 5rem !important;
}



.info-heading p{
  margin-top: 0.5rem;
  line-height: 27px;
  color: #1A1E21
}

.ratings-reviews h3 {
  margin: 2rem 0rem;
}

.ratingheading p {
  font-size: 14px;

  color: #1F2229;
  /* margin-top: 4rem; */
}

.inputtag {
  width: 298px;
  height: 50px;
  background-color: #F7F9FD;
  text-align: center;
  margin-top: 1rem;
  border: none;
}

.inputtag::placeholder {
  color: black;
}

.para-review-point{
  margin-top: 4rem;
  margin-left:10px;
}


.card-div {
  height: auto;
text-align: left;
  padding: 20px;
  margin-bottom: 20px;
  background-color:#F7FBFF !important;
  border-radius: 10px;
}

.css-8atqhb {
  width: 100%;
  max-width: 220px;
}

.show-btn{
  background-color: rgb(240, 240, 240);
  border-radius: 3px;
  border: none;
  font-size: 14px;
  padding: 13px 82px;
  /* color: white; */
  cursor: pointer;
  font-weight:bold;
}
.show-btn:hover{
  background: transparent linear-gradient(90deg, #E54949 0%, #E24D4D 4%, #E54949 30%, #CB4444 61%, #9B1F1F 100%) 0% 0% no-repeat padding-box;
color: white;
  transition: 0.5s;
}

.card-div h1 {
  font-size: 18px;
  font-weight: bold;
  color: #1A1E21;
}

.inside-flex {
  display: flex;
  margin-top: 10px;
}

.inside-flex h5 {
  font-size: 13px;
  margin-top: 6px;
}

.dynamic-text{
  margin-top: 20px !important;
}

.card-div p {
  margin-top: 3px;
  color: #2F3539;
}

.last-para {
  font-size: 13px;
  color: #56687B !important;
}

.seeall-btn {
  text-align: center;
  padding-bottom: 40px;
  padding-right: 20px;
}

.seeall-btn button {
  border-radius: 3px;
  cursor: pointer;
 width: 100%;
 height:50px;
  padding: 9px 72px;
  border: none;
  color: #000000;
  font-size: 17px;
  background-color: rgb(240, 240, 240);
}

.seeall-btn button:hover{
  background: transparent linear-gradient(90deg, #E54949 0%, #E24D4D 4%, #E54949 30%, #CB4444 61%, #9B1F1F 100%) 0% 0% no-repeat padding-box;
  color: white;
  transition: 0.5s;
}

.show {
  background-color: rgb(207, 207, 207);

}

.dialogbox {
  padding: 16px;
  position: relative;
  width: 424px;
height: auto;
overflow-x: hidden;
}

.dialogbox1 {
  padding: 16px;
  position: relative;
  width: 372px;
  height: 240px;
}

.dialogbox1 button {
  background-color: black;
  width: 100%;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 2rem;
  cursor: pointer;
}


.dialogheader {
  display: flex;
  flex-direction: column;
}

.dialogheader1 h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #B13434;
  font-weight: bold;
}

.dialogheader1 {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.dialogheader1 p {
  color: #95A5B5;
  font-size: 14px;
  text-align: center;
  margin-top: 3px;
}


.dialogheader h1 {
  display: flex;
  color: #1A1E21 !important;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}



.dialogheader1 h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:20px;
  text-align: center;
  font-weight: bold;
}

.dialogheader p {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #95A5B5;
  font-size: 16px;
  margin-top: 10px;
}

.dialogbox h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #242424;
  font-size: 14px;
  margin-top:6px;

}

.icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin:auto;
  width: 62px;
  height: 62px;
  margin-top:10px;
}
.dialogbox p{
  font-size:12px;
  margin-top:2px;
}

.dialogheader p{
  margin-top: 0px;
}
.dialogbox label{
  font-size:40px;
  margin-top:3px;

}



.dialogheader p {
  margin-top: 0px;
}



.forms {
  padding: 5px 8px;
}

.forms input{
  padding-left: 20px;
}

.forms textarea{
  padding-left: 20px;
  padding-top: 10px;
}

.forms ::placeholder{
  color: #56687B;
  font-size: 12px;
  
}

.forms textarea{
  background-color: #F0F3F7;
  border: none;
  width: 100%;
}

.forms label{
  font-size:14px;
  margin-top:10px;
  margin-top:10px;
}

.forms textarea {
  padding-left: 20px;
  padding-top: 10px;
}

.forms ::placeholder {
  color: #56687B;
  font-size: 12px;

}

.forms textarea {
  background-color: #F0F3F7;
  border: none;
  width: 100%;
}

.forms label {
  font-size: 14px;
  margin-top: 10px;
  margin-top: 10px;
}

.staricon {
  padding: 0px 8px;

}
.staricon p{
  font-size:14px;
  margin-top:10px;
  margin-top:10px;
}

.form-control1 {

  border: none;
  background: #F0F3F7 0% 0% no-repeat padding-box;
  height:30px;
  width:100%;
}
.form-control2{
  border:none;
  background:  #F0F3F7 0% 0% no-repeat padding-box;
  height:6rem;
  width:100%;
}
.dialogboxbutton{
  background-color: black;
  width:100%;
  color:white;
  padding-top:5px;
  padding-bottom:5px;
  margin-top:20px;
  cursor: pointer;
  text-align: center;
  font-size:18px;
}
.closepopup{
  position:absolute;
  top:0;
  right:0;
 cursor:pointer;
 padding:8px;
}

.form-control2 {
  border: none;
  background: #F0F3F7 0% 0% no-repeat padding-box;
  height: 6rem;
  width: 100%;
}


.closepopup {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 8px;
}

.dialog-icon {
  margin: auto;
}

.dialog-icon h2 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: #cb9d9d;
}

 .star-rating-icon label{
  color: #B13434 !important;
  border-radius: 15px !important;
  width:50%;
}  
.star-rating-icon-review{
  color: #B13434 !important;
}

.css-ryrseu-MuiRating-root{
  color:#B13434 !important;
  border-radius: 15px !important;
}

.last-grid{
  height: 172px;
  width: 100%;
  margin-top: 2rem;
  background-color: #F7FBFF;
}

.parent-grid-flex{
  display: flex;
  justify-content: space-between;
}

.parent-grid-flex h1{
  font-size: 20px;
}

.parent-grid-flex p{
  font-size: 15px;
  color: blue;
}

.parent-grid-flex1{
  display: flex;
  justify-content: space-between;
}

.text-grid-inside-flex{
  display: flex;
}

.progras-tag{
  color: red;
}

.middle {
  margin-top: 2rem;
  float: left;
  width: 70%;
 
}

.numbers-grid{
  margin-left: 5.5rem;
  color: #14344A;
  font-size: 12px;
}

.bar-container span
{
  background-color: #56687B !important;
}






.bar-5 {
  width: 85%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;  
}

.bar-5-4{
  width: 20%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;  
}

.bar-5-3{
  width: 42%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;  
}

.bar-5-2{
  width: 28%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;  
}

.bar-5-1{
  width: 70%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;  
}

.starrating{
  margin: 20px;
}

.img-grid1{

  margin-bottom: 15px;
}

.img-grid2{
  width: 70%;
}

.last-grid-page{
  background-color: #F7FBFF;
  border-bottom-left-radius:10px;
  border-bottom-right-radius:10px;
}

.last-grid-flex{
  display: flex;
  margin: 0px 20px;
  padding-top: 30px;
  justify-content: space-between;
}

.last-grid-flex h1{
  font-size: 20px;
}

.last-grid-icons{
  display: flex;
}

.last-heading-icons{
  color: #326DBE;
  margin-top: 5px;
  cursor: pointer;
}

.last-grid-flex p{
  color: #326DBE;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
}

.num-flex{
  display: flex;
}

.css-ryrseu-MuiRating-root {
  color: #B13434 !important;
  border-radius: 15px !important;
}

.last-grid {
  height: 172px;
  width: 100%;
  margin-top: 2rem;
  background-color: #F7FBFF;
}

.parent-grid-flex {
  display: flex;
  justify-content: space-between;
}

.parent-grid-flex h1 {
  font-size: 20px;
}

.parent-grid-flex p {
  font-size: 15px;
  color: blue;
}

.parent-grid-flex1 {
  display: flex;
  justify-content: space-between;
}

.text-grid-inside-flex {
  display: flex;
}

.progras-tag {
  color: red;
}

.middle {
  margin-top: 2rem;
  float: left;
  width: 70%;
}

.bar-container {
  width: 142%;
  display: flex;
  background-color: #f1f1f1 !important;
  text-align: center;
  color: white;
  border-radius: 15px;
  height: 8px !important;
  margin-top: 7px;
  margin-left: 15px;
}

/* .bar-5 {
  width: 60%;
  height: 18px;
  background-color: #56687B;
  border-radius: 15px;
  height: 8px;
} */

.num-flex {
  display: flex;
}

@media only screen and (max-width: 920px) {
  .rateandreview {
    display: flex;
    justify-content: center;

  }


  .rateandreview2 {
    display: flex;
    justify-content: center;


  }

  .review2 {
    width: 100px;
    display: flex;
    justify-content: center;
  }

  .starrating {
    display: flex;
    justify-content: center;
    margin-top: 20px !important;
  }

  .show {
    width: 200px !important;
    padding-right: 0px;
  }

  .MuiGrid-root {
    display: unset;


  }

  .seeall-btn {
    width: 100% !important;
    padding-right: 0px;
  }

}
.staricon{
  color:'yellow'
}


@media (min-width:320px) and (max-width:767px){
  .dialogbox {
    width: 100%;
}

.arif-twitter-flex{
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0px;
  display: block !important;
  /* background-color: #F7FBFF;
    border-radius: 8px; */
}

.icon-texts{
  background-color: #F7FBFF !important;
    border-radius: 8px;
    /* max-width: 300px !important; */
}



.seeall-btn button {
  border-radius: 3px;
  cursor: pointer;
 width: 100%;
 height:77px;
  padding: 9px 72px;
  border: none;
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(240, 240, 240);
}


.dialogheader1{
  margin-top: 0px;
}
.dialogbox1 button{
  margin-top: 1rem;
  cursor: pointer;
}
.dialogbox1{
  padding: 16px;
  position:relative;
 width: 100%;
 height: 100%;
}

.bar-container {
  width: 100%;
  display: flex;
  background-color: #f1f1f1 !important;
  text-align: center;
  color: white;
  border-radius: 15px;
  height: 8px !important;
  margin-top: 7px;
  margin-left: 15px;
}

}
.chipbtn{
  height:22px;
  border-radius: 8px;
}
.dialogboxbutton:disabled{
  background-color: #95A5B5;
  cursor:default;
}
.disbutton{
  background-color: #f3a4a4;
  border: none;
  border-radius: 4px;
  color: #FFFFFF;
  padding: 7px 48px;
  text-decoration: none;
}
.binding-twit-img{
  width: 30px;
  height: 30px;
}
.web-icon{
  color:#03a9f4 !important;
  width: 30px !important;
  height: 30px !important;
}
.web-icon:hover{
  color:#03a9f4 !important;
  width: 30px !important;
  height: 30px !important;
}
.arif-twitter-flex{
  margin-left:0px !important;
  width:100% !important;
}
